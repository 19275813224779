<template>
  <!--饼图 公共组件-->
  <div style="width: 100%; height: 100%;" :id="itemId"></div>
</template>

<script>
import echarts from "../../../lib/echarts";

export default {
  name: "PieChart",
  components: {},
  data() {
    return {
      seriesName: "",
      seriesData: []
    }
  },

  props: {
    itemId: {
      type: String,
      default: ""
    }
  },

  methods: {
    // 获取数据
    getPieceList(name, data) {
      this.seriesName = name;
      this.seriesData = data;
      this.initial();
    },

    initial() {
      let item = document.getElementById(this.itemId);
      var myChart = echarts.init(item);
      myChart.setOption({
        backgroundColor: '#fff',
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          bottom: "bottom",
          icon: "circle",
          align: 'left',
        },
        series: [
          {
            name: this.seriesName,
            type: 'pie',
            radius: '60%',
            center: ['50%', '45%'],
            data: this.seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 1,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.1)'
              }
            }
          }
        ]
      });
    }
  }
}
</script>
